<template>
  <div>
    <b-sidebar
      sidebar-class="w-100"
      :visible="true"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="hideModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Rating') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <div
          id="container"
          class="w-100 b-sidebar-scrollarea-iframe"
        />

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar,
} from 'bootstrap-vue'
import powerbiModule from '@store-modules/growth/powerbi/index'
import { mapGetters } from 'vuex'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import checkNested from 'check-nested'
import atob from 'atob'
import useCapacitor from '@core-custom/utils/capacitor'

export default {
  components: {
    BSidebar,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  computed: {
    ...mapGetters('powerbi', ['config', 'reports', 'paramsUrl']),
    ...mapGetters('auth', ['currentUser']),
  },
  async created() {
    try {
      this.$root.$emit('app::loading', true)

      let idReport = ''
      let filtroReport = ''
      let urlEmbed = ''

      const oPbi = async () => Promise.all([
        import(/* webpackChunkName: "chunk-powerbi-client" */ 'powerbi-client'),
      ]).then(([res]) => res)

      const pbi = await oPbi()

      await store.dispatch('powerbi/getReports', { idReport: '' }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      if (Object.keys(this.reports).length > 0) {
        Object.keys(this.reports).forEach(id => {
          if (checkNested(this.reports[id], 'txt') && (this.reports[id].txt.toLowerCase() === 'ranking')) {
            idReport = id

            if (checkNested(this.config.reports[id], 'embedUrl')) {
              urlEmbed = this.config.reports[id].embedUrl
            }

            if (checkNested(this.reports[id], 'filtro')) {
              filtroReport = this.reports[id].filtro
            }
          }
        })
      }

      if (filtroReport !== '') {
        urlEmbed += `&filter=${filtroReport}&isMobile=true`
        urlEmbed = urlEmbed.replace('{{ID_USER}}', atob(this.currentUser.id))
        urlEmbed = urlEmbed.replace('{{ID_HUB}}', this.currentUser.idHUB)
        urlEmbed = urlEmbed.replace('{{ID_MORADA}}', this.currentUser.idMoradaHUB.join(','))
      }

      const permissions = pbi.models.Permissions.All

      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Aad,
        accessToken: this.config.token,
        embedUrl: urlEmbed,
        id: idReport,
        permissions,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
          layoutType: pbi.models.LayoutType.MobilePortrait,
        },
      }

      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)
      const reportContainer = document.getElementById('container')
      const report = powerbi.embed(reportContainer, config)

      report.iframe.style.border = 'none'
      report.off('loaded')
      report.off('rendered')
      report.on('error')

      this.$root.$emit('app::loading', false)
    } catch (err) {
      this.$root.$emit('app::loading', false)
      this.$emit('closeModal')
      this.showMsgErrorRequest({ message: this.$t('Problema ao carregar o rating') })
    }
  },
  mounted() {
    if (useCapacitor.isNative() === true) {
      useCapacitor.unlockScreen()
    }
  },
  beforeCreate() {
    this.isRouterLeaving = false
    if (this.$store.hasModule('powerbi')) {
      this.$store.unregisterModule('powerbi')
    }
    this.$store.registerModule('powerbi', powerbiModule)
  },
  beforeRouteLeave(to, from, next) {
    this.isRouterLeaving = true
    next()
  },
  beforeDestroy() {
    if (this.isRouterLeaving) {
      this.$store.unregisterModule('powerbi')
    }

    if (useCapacitor.isNative() === true) {
      useCapacitor.lockScreen()
    }
  },
  methods: {
    hideModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
